import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import sidebarBg from "../../images/bgSide.jpg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import {
  FaUsers,
  FaChartArea,
  FaServer,
  FaUserCheck,
  FaWhmcs,
  FaUserShield,
  FaInfo,
  FaLock,
  FaPowerOff,
  FaQuestionCircle,
  FaTicketAlt,
  FaRegBell,
  FaBookReader,
  FaBuffer,
  FaHouseDamage,
  FaStream,
  Fa500Px,
  FaMoneyBillWave,
  FaDonate,
  FaCcAmazonPay,
  FaPlug,
  FaTable,
  FaSitemap,
  FaArtstation,
  FaAudible,
  FaInbox,
} from "react-icons/fa";
import "../../styles/App.scss";
import sysJwt from "../../utils/sysJwt";

const Aside = ({ username, isLoading, handleLogout }) => {
  const [toggled, setToggled] = useState(false);

  const handleClick = (event) => {
    setToggled(!toggled);
  };

  return (
    <div className={`app ${1 === 1 ? "rtl" : ""} ${1 === 1 ? "toggled" : ""}`}>
      <ProSidebar
        image={sidebarBg}
        collapsed={toggled}
        toggled={true}
        breakPoint="md"
        width={230}
      >
        <SidebarHeader>
          <div
            style={{
              padding: "24px",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 14,
              letterSpacing: "1px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {!isLoading ? (
              <i className="fa fa-bars" onClick={handleClick} />
            ) : (
              ""
            )}
            {isLoading ? (
              <ThreeCircles color="white" height="25" width="25" />
            ) : (
              <a href="/dashboard/home">Dashboard</a>
            )}
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            {sysJwt.isAuthorized(["CABD"]) && (
              <SubMenu icon={<FaWhmcs />} title="System">
                {sysJwt.isAuthorized(["CABD", "CABE"]) && (
                  <MenuItem icon={<FaUsers />}>
                    <Link to="/dashboard/usermanagement">Users</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CABD", "CAC5"]) && (
                  <MenuItem icon={<FaUserShield />}>
                    <Link to="/dashboard/rolemanagement">Roles</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CABD", "CAD5"]) && (
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/systemparameters">
                      System Parameters
                    </Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {sysJwt.isAuthorized(["CAF2"]) && (
              <SubMenu icon={<FaBookReader />} title="Definitions">
                {sysJwt.isAuthorized(["CAF2", "CB04"]) && (
                  <MenuItem icon={<FaQuestionCircle />}>
                    <Link to="/dashboard/faq">FAQs</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB10"]) && (
                  <MenuItem icon={<FaRegBell />}>
                    <Link to="/dashboard/notification">Notifications</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB12"]) && (
                  <MenuItem icon={<FaBuffer />}>
                    <Link to="/dashboard/category">Category</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB16"]) && (
                  <MenuItem icon={<FaStream />}>
                    <Link to="/dashboard/Subcategory">Subcategory</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB1A"]) && (
                  <MenuItem icon={<FaHouseDamage />}>
                    <Link to="/dashboard/units">Units</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB1E"]) && (
                  <MenuItem icon={<FaUserCheck />}>
                    <Link to="/dashboard/owners">Owners</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB25"]) && (
                  <MenuItem icon={<Fa500Px />}>
                    <Link to="/dashboard/expensetypes">Expense Types</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB47"]) && (
                  <MenuItem icon={<FaAudible />}>
                    <Link to="/dashboard/incometypes">Income Types</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF2", "CB43"]) && (
                  <MenuItem icon={<FaArtstation />}>
                    <Link to="/dashboard/paytypes">Pay Types</Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {sysJwt.isAuthorized(["CB33"]) && (
              <MenuItem icon={<FaCcAmazonPay />}>
                Payments <Link to="/dashboard/payments" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB36"]) && (
              <SubMenu icon={<FaPlug />} title="Electricity">
                {sysJwt.isAuthorized(["CB36", "CB37"]) && (
                  <MenuItem icon={<FaTable />}>
                    <Link to="/dashboard/vouchertypes">Voucher Types</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CB36", "CB3A"]) && (
                  <MenuItem icon={<FaSitemap />}>
                    <Link to="/dashboard/vouchersstock">Vouchers Stock</Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {sysJwt.isAuthorized(["CB29"]) && (
              <MenuItem icon={<FaMoneyBillWave />}>
                Expenses <Link to="/dashboard/expenses" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB4B"]) && (
              <MenuItem icon={<FaInbox />}>
                Incomes <Link to="/dashboard/incomes" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB2D"]) && (
              <MenuItem icon={<FaDonate />}>
                Installments <Link to="/dashboard/installments" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CB3E"]) && (
              <MenuItem icon={<FaTicketAlt />}>
                Tickets <Link to="/dashboard/tickets" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CAEC"]) && (
              <MenuItem icon={<FaChartArea />}>
                Reports <Link to="/dashboard/reports" />
              </MenuItem>
            )}
            <MenuItem icon={<FaInfo />}>
              <Link to="/dashboard/applicationinfo">About System</Link>
            </MenuItem>
            <MenuItem icon={<FaLock />}>
              <Link to="/dashboard/changepassword">Change Password</Link>
            </MenuItem>
            <MenuItem icon={<FaPowerOff />}>
              <a onClick={() => handleLogout()}>Logout</a>
            </MenuItem>
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <a className="sidebar-btn" rel="noopener noreferrer">
              <FaUserCheck />
              <span
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {username}
              </span>
            </a>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default Aside;
