/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import GridTable from "@nadavshaar/react-grid-table";
import { Calendar } from "primereact/calendar";

class Tickets extends Component {
  state = {
    data: {
      reply: "",
    },

    dates: [
      new Date(new Date().setDate(new Date().getDate() - 60)),
      new Date(),
    ],

    contentHeader: "Tickets",
    addButtonLabel: "Reply",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetTickets",
    addUrl: "/api/data/AddTicketReply",

    inModalMode: false,
    modalHeaderAdd: "Add Reply",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalWidth: "80vw",

    editEntityId: 0,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "ID",
        field: "ID",
      },
      {
        id: 2,
        label: "Owner",
        field: "OWNER_NAME",
      },
      {
        id: 3,
        label: "Unit",
        field: "UNITS",
      },
      {
        id: 4,
        label: "Title",
        field: "TITLE",
      },
      {
        id: 5,
        label: "Body",
        field: "BODY",
      },
      {
        id: 6,
        label: "Reply",
        field: "REPLY",
      },
      {
        id: 7,
        label: "Insert Date",
        field: "INSERT_DATE",
      },
      {
        id: 8,
        label: "Rply Date",
        field: "REPLY_DATE",
      },
      {
        id: 9,
        label: "Last Update",
        field: "LAST_UPDATE",
      },
      {
        id: 10,
        label: "Actions",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CB3F"]) && (
                <div className="inner" title="Reply">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.setState({
                        inEditMode: true,
                        editEntityId: data.ID,
                      });
                      this.showForm();
                    }}
                  >
                    <i className="fa fa-reply" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    /*var dateTo = new Date();
    var dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 30);
    var datee = [dateFrom, dateTo];
    this.setState({ dates: datee }).then(() => {
      this.getData();
    });*/
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
      allClaims: [],
    });

    this.getData();
  }

  getData() {
    console.log(this.state.dates);
    post(
      this.state.dataUrl,
      {
        dateFrom: this.state.dates[0],
        dateTo: this.state.dates[1],
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  getEditFormData(row) {
    this.setState({
      editEntityId: row.ID,
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);
    submitData["ticketId"] = this.state.editEntityId;
    post(this.state.addUrl, submitData, this.props.updateIsLoading).then(
      (apiResponse) => {
        console.log(apiResponse);
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModal();
        }
      }
    );
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            Date:
            <Calendar
              selectionMode="range"
              showIcon="true"
              value={this.state.dates}
              className="flex-item-left mr-2"
              onChange={(e) => this.setState({ dates: e.value })}
              style={{ width: "250px" }}
            ></Calendar>
            &nbsp;
            <Button
              label="Search"
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                this.getData();
              }}
            />
            &nbsp;&nbsp;
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">{this.state.modalHeaderAdd}</h5>}
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabelAdd}
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "reply",
                label: "reply",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 25, 30]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default Tickets;
