/* eslint-disable no-unused-expressions */

import axios from "axios";
import sysJwt from "./sysJwt";
import { toastSuccess, toastError } from "./sysToast";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function post(url, data, updateIsLoading, responseType = "json") {
  updateIsLoading(true);

  var postData =
    data instanceof FormData
      ? data
      : {
          ...data,
        };

  return axios
    .post(url, postData, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("748784"),
        DFP: sysJwt.getIsDefaultPwd(),
      },

      responseType: responseType,
    })
    .then((apiResponse) => {
      updateIsLoading(false);

      if (apiResponse.headers.refreshedtoken) {
        sessionStorage.setItem("748784", apiResponse.headers.refreshedtoken);
      }

      if (sysJwt.getIsDefaultPwd() === "true") {
        if (window.location.pathname !== "/dashboard/changepassword") {
          toastError(
            "Please change the system default password with a new password of your own"
          );
          sleep(2000).then(() =>
            window.location.replace("/dashboard/changepassword")
          );
        }
      }

      if (apiResponse.data.respCode === 0) {
        apiResponse.data.respDesc
          ? toastSuccess(apiResponse.data.respDesc)
          : null;
        return apiResponse;
      } else {
        if (apiResponse.status === 401) {
          window.location.replace("/login");
        } else {
          toastError(apiResponse.data.respDesc);
          return apiResponse;
        }
      }
    })
    .catch((apiResponse) => {
      updateIsLoading(false);
      if (apiResponse.response.status === 401) {
        window.location.replace("/login"); 
      } else if (apiResponse.response.status === 403) {
        toastError(
          "The entries contain prohibited characters, please delete them and try again"
        );
      } else {
        toastError(apiResponse.response.statusText);
      }

      return apiResponse;
    });
}
