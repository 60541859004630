import React, { Component } from "react";
import sysJwt from "../../utils/sysJwt";
import AppRoutes from "./routes";
import { ToastContainer, Flip } from "react-toastify";
import Aside from "../commonComponents/Aside";

class Dashboard extends Component {
  state = {
    Username: "",
    isLoading: false,
    tempSharedData: {},
  };

  componentDidMount() {
    this.setState({
      Username: sysJwt.getUsername(),
    });
  }

  handleIsLoadingChange = (isLoadingStatus) => {
    this.setState({ isLoading: isLoadingStatus });
  };

  updateTempSharedData = (tempData) => {
    this.setState({ tempSharedData: tempData });
  };

  handleLogOut = () => {
    window.location.replace("/login");
    sessionStorage.removeItem("748784");
  };

  render() {
    return (
      <React.Fragment>
        <div className="flex-container">
          <Aside
            username={this.state.Username}
            isLoading={this.state.isLoading}
            handleLogout={this.handleLogOut}
          />
          <div className="bms-content pt-3">
            <AppRoutes
              isLoading={this.state.isLoading}
              tempSharedData={this.state.tempSharedData}
              updateIsLoading={(isLoadingStatus) =>
                this.handleIsLoadingChange(isLoadingStatus)
              }
              updateTempSharedData={(tempData) =>
                this.updateTempSharedData(tempData)
              }
            />
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          closeButton={false}
          rtl={false}
          transition={Flip}
          newestOnTop
          closeOnClick
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

export default Dashboard;
