/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import queryString from "query-string";
import InfoPopUp from "../../commonComponents/infoPopUp";
import { payBillPrint } from "../../../utils/printFroms";

class ServicBills extends Component {
  state = {
    data: {
      dueDate: "",
      payDate: "",
    },

    queryString: queryString.parse(window.location.search),
    Username: "",
    contentHeader:
      "Unit Service Bills - " +
      queryString.parse(window.location.search).unitName,
    addButtonLabel: "Create Installment Schedule",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetUnitServicBills",
    singleItemUrl: "/api/data/GetUnitInstallment",
    addUrl: "/api/data/CreateUnitInstallments",
    editUrl: "/api/data/EditInstallment",
    deleteUrl: "/api/data/DeleteInstallment",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "Add Installment",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "Edit Installment",
    modalSubmitButtonLabelEdit: "Edit",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",
    inModalCreateMode: false,
    inModalPayMode: false,
    inModalAddMode: false,

    editEntityId: 0,
    tableData: [],
    billId: "",

    tableColumns: [
      {
        id: 1,
        field: "ID",
        label: "ID",
        width: "75px",
      },
      {
        id: 2,
        field: "DUE_AMOUNT",
        label: "Due Amount",
      },
      {
        id: 3,
        field: "DUE_DATE",
        label: "Due Date",
      },
      {
        id: 4,
        field: "IS_PAID",
        label: "Paid",
        cellRenderer: ({ data }) => {
          return data.IS_PAID === "Y" ? (
            <span
              style={{
                color: "white",
                backgroundColor: "#28A745",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;Yes&nbsp;</b>
            </span>
          ) : (
            <span
              style={{
                color: "white",
                backgroundColor: "#DC3545",
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;No&nbsp;</b>
            </span>
          );
        },
      },
      {
        id: 5,
        field: "PAY_DATE",
        label: "Pay Date",
      },
      {
        id: 6,
        label: "Insert Date",
        field: "INSERT_DATE",
      },
      {
        id: 7,
        label: "Actions",
        field: "SYS_ACTIONS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CB34"]) && data.IS_PAID === "N" && (
                <div className="inner" title="Pay">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() =>
                      this.setState({
                        inModalPayMode: true,
                        billId: data.ID,
                      })
                    }
                  >
                    <i className="fa fa-money" />
                  </button>
                </div>
              )}
              <div className="inner" title="Payment Info">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handleMoreInfo(data);
                  }}
                >
                  <i className="fa fa-info" />
                </button>
              </div>
              <div className="inner" title="Print">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handlePrint(data);
                  }}
                >
                  <i className="fa fa-print" />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
  };

  handlePrint(row) {
    post(
      "/api/data/GetPrintBill",
      {
        id: row.ID,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        payBillPrint(
          apiResponse.data.respData.paidBills,
          apiResponse.data.respData.totalAmount,
          apiResponse.data.respData.username,
          apiResponse.data.respData.discountType,
          apiResponse.data.respData.discountAmount,
          apiResponse.data.respData.paidAmount
        );
      }
    });
  }

  handleMoreInfo(row) {
    post(
      "/api/data/GetBillPaymentInfo",
      {
        paymentId: row.PAYMENT_ID,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        const jsonData = {
          "Pay Amount": apiResponse.data.respData.payment.PAY_AMOUNT,
          "Pay Date": apiResponse.data.respData.payment.PAY_DATE,
          "Pay Method": apiResponse.data.respData.payment.PAY_METHOD,
          "Order ID": apiResponse.data.respData.payment.ORDER_ID,
          GET_STATUS_RESP:
            apiResponse.data.respData.order !== null
              ? apiResponse.data.respData.order.GET_STATUS_RESP
              : "",
        };

        confirmAlert({
          title: row.ID,
          childrenElement: () => (
            <InfoPopUp
              jsonData={jsonData}
              titlesColumnSize={4}
              valuesColumnSize={8}
              popUpWidth="1000px"
            />
          ),
          buttons: [
            {
              label: "Close",
              onClick: () => {
                null;
              },
            },
          ],
        });
      }
    });
  }

  componentDidMount() {
    this.getData();
    this.setState({
      Username: sysJwt.getUsername(),
    });
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModalPay() {
    this.setState({
      inModalPayMode: false,
    });
    this.getData();
  }

  getData() {
    post(
      this.state.dataUrl,
      { unitId: this.state.queryString.unitId },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  doSubmitPay() {
    post(
      "/api/data/PayServiceBill",
      {
        id: this.state.billId,
        payDate: this.state.data.payDate,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModalPay();
        payBillPrint(
          apiResponse.data.respData.paidBills,
          apiResponse.data.respData.totalAmount,
          apiResponse.data.respData.username
        );
      }
    });
  }

  doSubmitAdd() {
    post(
      "/api/data/AddServiceBill",
      {
        unitId: this.state.queryString.unitId,
        dueDate: this.state.data.dueDate,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModalAdd();
      }
    });
  }

  closeModalAdd() {
    this.setState({
      inModalAddMode: false,
    });
    this.getData();
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            <Button
              label="Back"
              icon=""
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                window.location.replace("/dashboard/units");
              }}
            />
            &nbsp;
            {sysJwt.isAuthorized(["CB40"]) && (
              <Button
                label="Add Bill"
                icon="fa fa-plus"
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inModalAddMode: true });
                }}
              />
            )}
            &nbsp;
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalPayMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModalPay()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">Pay Bill</h5>}
          footer={
            <div>
              <Button
                label="Pay"
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmitPay()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModalPay()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "datetime",
                id: "payDate",
                label: "Pay Date",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <Dialog
          visible={this.state.inModalAddMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModalAdd()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">Add Bill</h5>}
          footer={
            <div>
              <Button
                label="Add"
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmitAdd()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModalAdd()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "datetime",
                id: "dueDate",
                label: "Due Date",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 25, 30]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default ServicBills;
