/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { expensePrint } from "../../../utils/printFroms";

class Subcategory extends Component {
  state = {
    data: {
      title: "",
      details: "",
      date: "",
      expenseType: "",
      amount: "",
      file: null,
      categoryId: "",
    },
    Username: "",
    allTypes: [],
    allCategory: [],
    dates: "",
    type: "",

    contentHeader: "Expenses",
    addButtonLabel: "Add Expense",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetExpenses",
    singleItemUrl: "/api/data/GetExpense",
    addUrl: "/api/data/AddExpense",
    editUrl: "/api/data/EditExpense",
    deleteUrl: "/api/data/DeleteExpense",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "Add Expense",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "Edit Expense",
    modalSubmitButtonLabelEdit: "Edit",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "Type",
        field: "EXPENSE_TYPE",
      },
      {
        id: 2,
        label: "Title",
        field: "TITLE",
      },
      {
        id: 3,
        label: "Details",
        field: "DETAILS",
      },
      {
        id: 4,
        label: "Date",
        field: "DATE",
      },
      {
        id: 5,
        label: "Amount",
        field: "AMOUNT",
      },
      {
        id: 6,
        label: "Category",
        field: "CATEGORY",
      },
      {
        id: 7,
        label: "Insert Date",
        field: "INSERT_DATE",
      },
      {
        id: 8,
        label: "Last Update",
        field: "LAST_UPDATE",
      },
      {
        id: 9,
        label: "Actions",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {data.ATTACHMENT ? (
                <div className="inner" title="Attachment">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.handleMoreInfo(data);
                    }}
                  >
                    <a href={data.ATTACHMENT} target="blank">
                      <i
                        className="fa fa-paperclip"
                        style={{ color: "black" }}
                      />
                    </a>
                  </button>
                </div>
              ) : (
                ""
              )}
              {sysJwt.isAuthorized(["CB2B"]) && (
                <div className="inner" title="Edit">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB2C"]) && (
                <div className="inner" title="Delete">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
              <div className="inner" title="Print">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    expensePrint(data, this.state.Username);
                  }}
                >
                  <i className="fa fa-print" />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getAllTypes();
    this.getAllCategory();
    this.setState({
      Username: sysJwt.getUsername(),
    });
  }

  getAllTypes() {
    post("/api/data/GetAllExpenseTypes", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          allTypes: apiResponse.data.respData.data,
        });
      }
    );
  }

  getAllCategory() {
    post("/api/data/getallcategories", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          allCategory: apiResponse.data.respData.data,
        });
      }
    );
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
      allClaims: [],
    });

    this.getData();
  }

  getData() {
    post(
      this.state.dataUrl,
      {
        dateFrom: this.state.dates[0],
        dateTo: this.state.dates[1],
        expenseType: this.state.type,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["title"] = apiResponse.data.respData.data[0].TITLE;
      data["details"] = apiResponse.data.respData.data[0].DETAILS;
      data["date"] = apiResponse.data.respData.data[0].DATE;
      data["expenseType"] = apiResponse.data.respData.data[0].TYPE_ID;
      data["amount"] = apiResponse.data.respData.data[0].AMOUNT;
      data["categoryId"] = apiResponse.data.respData.data[0].CATEGORY_ID;
      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "Delete Expense",
      message: "Are you sure to delete this expense?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "Cancel",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmit() {
    if (this.state.inEditMode) {
      const submitData = Object.assign({}, this.state.data);
      submitData["id"] = this.state.editEntityId;
      post(
        this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
        submitData,
        this.props.updateIsLoading
      ).then((apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModal();
        }
      });
    } else {
      const data = new FormData();
      if (this.state.data.file !== null) {
        data.append("File", this.state.data.file, this.state.data.file.name);
      }

      data.append(
        "requestData",
        JSON.stringify({
          title: this.state.data.title,
          details: this.state.data.details,
          date: this.state.data.date,
          expenseType: this.state.data.expenseType,
          amount: this.state.data.amount,
          categoryId: this.state.data.categoryId,
        })
      );

      post(
        this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
        data,
        this.props.updateIsLoading
      ).then((apiResponse) => {
        console.log(apiResponse);
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModal();
          if (!this.state.inEditMode) {
            expensePrint(apiResponse.data.respData, this.state.Username);
          }
        }
      });
    }
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            Date:
            <Calendar
              selectionMode="range"
              showIcon="true"
              value={this.state.dates}
              className="flex-item-left mr-2"
              onChange={(e) => this.setState({ dates: e.value })}
              style={{ width: "250px" }}
            ></Calendar>
            Type:
            <Dropdown
              value={this.state.type}
              showClear={true}
              options={this.state.allTypes}
              filter={true}
              onChange={(e) => this.setState({ type: e.target.value })}
            />
            &nbsp;
            <Button
              label="Search"
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                this.getData();
              }}
            />
            &nbsp;&nbsp;
            {sysJwt.isAuthorized(["CB2A"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
                style={{ width: "160px" }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "dropdown",
                id: "expenseType",
                label: "Expense Type",
                options: this.state.allTypes,
                disabled: false,
              },
              {
                type: "text",
                id: "title",
                label: "Title",
                disabled: false,
              },
              {
                type: "text",
                id: "amount",
                label: "Amount",
                disabled: false,
              },
              {
                type: "text",
                id: "details",
                label: "Details",
                disabled: false,
              },
              {
                type: "datetime",
                id: "date",
                label: "Date",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "categoryId",
                label: "Category",
                options: this.state.allCategory,
                disabled: false,
              },
              {
                type: "fileupload",
                id: "file",
                label: "Attachment",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 25, 30]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default Subcategory;
