import React, { Component } from "react";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import GridTable from "@nadavshaar/react-grid-table";
import sysJwt from "../../../utils/sysJwt";

class UserManagement extends Component {
  state = {
    data: {
      type: "",
      categoryId: "",
      subcategoryId: "",
      ownerId: "",
      title: "",
      body: "",
      topic: "",
    },

    contentHeader: "Notifications",
    addButtonLabel: "Send New Notif.",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/getpublicnotf",
    addUrl: "/api/data/sendpublicnotf",

    inModalMode: false,
    modalHeaderAdd: "Send Notification",
    modalSubmitButtonLabelAdd: "Send",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalWidth: "50vw",

    topicDropdown: [],
    allCategory: [],
    allSubcategory: [],
    allOwner: [],
    allType: [
      { value: "1", label: "Public" },
      { value: "2", label: "Category" },
      { value: "3", label: "Subcategory" },
      { value: "4", label: "Owner" },
    ],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "Title",
        field: "TITLE",
      },
      {
        id: 2,
        label: "Body",
        field: "BODY",
      },
      {
        id: 3,
        label: "Insert Date",
        field: "INSERT_DATE",
      },
      {
        id: 4,
        label: "Last Update",
        field: "LAST_UPDATE",
      },
    ],
  };

  componentDidMount() {
    this.getData();
    this.getAllCategory();
    this.getAllOwner();
    this.getAllSubcategory();
  }

  getAllCategory() {
    post("/api/data/getallcategories", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          allCategory: apiResponse.data.respData.data,
        });
      }
    );
  }

  getAllSubcategory() {
    post(
      "/api/data/GetAllSubcategoriesCatName",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allSubcategory: apiResponse.data.respData.data,
      });
    });
  }

  getAllOwner() {
    post("/api/data/getallowner", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          allOwner: apiResponse.data.respData.data,
        });
      }
    );
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });

    this.getTopicDropdown();
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      data: data,
      topicDropdown: [],
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getTopicDropdown() {
    post("/api/data/getallfcmtopic", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          topicDropdown: apiResponse.data.respData.data,
        });
      }
    );
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    post(this.state.addUrl, submitData, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModal();
        }
      }
    );
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB11"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">{this.state.modalHeaderAdd}</h5>}
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabelAdd}
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={false}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "dropdown",
                id: "type",
                label: "Type",
                options: this.state.allType,
                disabled: false,
              },
              {
                type: "dropdown",
                id: "topic",
                label: "FCM Topic",
                options: this.state.topicDropdown,
                disabled: this.state.data.type === "1" ? false : true,
              },
              {
                type: "dropdown",
                id: "categoryId",
                label: "Category",
                options: this.state.allCategory,
                disabled: this.state.data.type === "2" ? false : true,
              },
              {
                type: "dropdown",
                id: "subcategoryId",
                label: "Subcategory",
                options: this.state.allSubcategory,
                disabled: this.state.data.type === "3" ? false : true,
              },
              {
                type: "dropdown",
                id: "ownerId",
                label: "Owner",
                options: this.state.allOwner,
                disabled: this.state.data.type === "4" ? false : true,
              },
              {
                type: "text",
                id: "body",
                label: "Body",
                disabled: false,
              },
              {
                type: "text",
                id: "title",
                label: "Title",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 25, 30]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default UserManagement;
