/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import GridTable from "@nadavshaar/react-grid-table";

class SystemParameters extends Component {
  state = {
    data: {
      ParameterName: "",
      ParameterValue: "",
      ParameterDescription: "",
    },

    contentHeader: "System Parameters",

    dataUrl: "/api/data/getsystemparameters",
    singleItemUrl: "/api/data/getsystemparameter",
    editUrl: "/api/data/editsystemparameter",

    inEditMode: false,
    inModalMode: false,
    modalHeaderEdit: "Edit Parameter",
    modalSubmitButtonLabelEdit: "Edit",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "60vw",

    editEntityId: 0,

    allInstitutions: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "Parameter Name",
        field: "PARAMETER_NAME",
      },
      {
        id: 2,
        label: "Parameter Value",
        field: "PARAMETER_VALUE",
      },
      {
        id: 3,
        label: "Parameter Description",
        field: "PARAMETER_DESCRIPTION",
      },
      {
        id: 4,
        label: "Actions",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CAD6"]) && (
                <div className="inner" title="Edit">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { ParameterId: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["ParameterName"] = apiResponse.data.respData.data[0].PARAMETER_NAME;
      data["ParameterValue"] =
        apiResponse.data.respData.data[0].PARAMETER_VALUE;
      data["ParameterDescription"] =
        apiResponse.data.respData.data[0].PARAMETER_DESCRIPTION;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["EditEntityId"] = this.state.editEntityId;
    }

    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModal();
      }
    });
  }

  doReInit() {
    post("/api/system/reinit", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
        }
      }
    );
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            <Button
              label="Refresh Cache"
              icon="fa fa-refresh"
              className="p-button-rounded p-button-secondary"
              disabled={this.props.isLoading}
              onClick={() => {
                this.doReInit();
              }}
            />
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">{this.state.modalHeaderEdit}</h5>
          }
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabelEdit}
                icon={this.state.modalSubmitButtonIconEdit}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "ParameterName",
                label: "Parameter Name",
                disabled: true,
              },
              {
                type: "text",
                id: "ParameterValue",
                label: "Parameter Value",
                disabled: false,
              },
              {
                type: "text",
                id: "ParameterDescription",
                label: "Parameter Description",
                disabled: true,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 25, 30]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default SystemParameters;
