/* eslint-disable no-unused-expressions */

import React from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { getDmsDate } from "../../utils/stringHelper";

function renderTextBox(input, props) {
  return (
    <div className="form-group" key={input.id}>
      <label htmlFor={input.id} style={{ display: "block" }}>
        {input.label}
      </label>
      <InputText
        id={input.id}
        name={input.id}
        value={props.data[input.id]}
        className="form-control form-control-sm"
        type={input.type}
        disabled={props.isLoading || input.disabled}
        tooltip={input.tooltip}
        onChange={(e) => {
          props.data[e.target.id] = e.target.value;

          props.updateForm(e.target.id, e.target.value);
        }}
      />
    </div>
  );
}

function renderFileUpload(input, props) {
  return (
    <div className="form-group" key={input.id}>
      <label htmlFor={input.id} style={{ display: "block" }}>
        {input.label}
      </label>
      <input
        id={input.id}
        name={input.id}
        type="file"
        className="form-control form-control-sm"
        disabled={props.isLoading || input.disabled}
        onChange={(e) => {
          props.data[e.target.id] = e.target.files[0];

          props.updateForm(e.target.id, e.target.files[0]);
        }}
      />
    </div>
  );
}

function renderDatePicker(input, props) {
  return (
    <div className="form-group" key={input.id}>
      <label htmlFor={input.id} style={{ display: "block" }}>
        {input.label}
      </label>
      <Calendar
        id={input.id}
        name={input.id}
        value={props.data[input.id]}
        className="form-control calendar"
        hourFormat={input.hourFormat}
        dateFormat={input.dateFormat}
        disabled={props.isLoading || input.disabled}
        tooltip={input.tooltip}
        onChange={(e) => {
          props.data[e.target.id] = e.target.value;

          props.updateForm(e.target.id, e.target.value);
        }}
      />
    </div>
  );
}

function renderTimePicker(input, props) {
  return (
    <div className="form-group" key={input.id}>
      <label htmlFor={input.id} style={{ display: "block" }}>
        {input.label}
      </label>
      <Calendar
        id={input.id}
        name={input.id}
        value={props.data[input.id]}
        className="form-control calendar"
        hourFormat={input.hourFormat}
        disabled={props.isLoading || input.disabled}
        tooltip={input.tooltip}
        timeOnly
        showTime
        onChange={(e) => {
          props.data[e.target.id] = e.target.value;

          props.updateForm(e.target.id, e.target.value);
        }}
      />
    </div>
  );
}

function renderDropdown(input, props) {
  return (
    <div className="form-group" key={input.id}>
      <label htmlFor={input.id}>{input.label}</label>
      <Dropdown
        id={input.id}
        name={input.id}
        value={props.data[input.id]}
        showClear={true}
        options={input.options}
        disabled={props.isLoading || input.disabled}
        filter={true}
        tooltip={input.tooltip}
        className="dropdown"
        onChange={(e) => {
          props.data[e.target.id] = e.target.value;
          props.updateForm(e.target.id, e.target.value);
          input.customOnChangeHandler
            ? input.customOnChangeHandler(e.target.value)
            : null;
        }}
      />
    </div>
  );
}

function renderMultiSelect(input, props) {
  return (
    <div className="form-group" key={input.id}>
      <label htmlFor={input.id}>{input.label}</label>
      <MultiSelect
        id={input.id}
        name={input.id}
        value={props.data[input.id]}
        placeholder="Choose from the list"
        fixedPlaceholder={true}
        scrollHeight="270px"
        filter={true}
        tooltip={input.tooltip}
        options={input.options}
        disabled={props.isLoading || input.disabled}
        className="multiselect"
        onChange={(e) => {
          props.data[e.target.id] = e.target.value;
          props.updateForm(e.target.id, e.target.value);
          input.customOnChangeHandler
            ? input.customOnChangeHandler(e.target.value)
            : null;
        }}
      />
    </div>
  );
}

const sysForm = (props) => {
  var leftSideMarkup = [];
  var rightSideMarkup = [];

  for (var i = 0; i < props.inputs.length; i++) {
    // Even inputs are to the left.
    if (i % 2 === 0) {
      if (
        props.inputs[i].type === "text" ||
        props.inputs[i].type === "password"
      ) {
        leftSideMarkup.push(renderTextBox(props.inputs[i], props));
      } else if (props.inputs[i].type === "dropdown") {
        leftSideMarkup.push(renderDropdown(props.inputs[i], props));
      } else if (props.inputs[i].type === "multiselect") {
        leftSideMarkup.push(renderMultiSelect(props.inputs[i], props));
      } else if (props.inputs[i].type === "datetime") {
        leftSideMarkup.push(renderDatePicker(props.inputs[i], props));
      } else if (props.inputs[i].type === "time") {
        leftSideMarkup.push(renderTimePicker(props.inputs[i], props));
      } else if (props.inputs[i].type === "fileupload") {
        rightSideMarkup.push(renderFileUpload(props.inputs[i], props));
      }

      // Odd inputs are to the right.
    } else {
      if (
        props.inputs[i].type === "text" ||
        props.inputs[i].type === "password"
      ) {
        rightSideMarkup.push(renderTextBox(props.inputs[i], props));
      } else if (props.inputs[i].type === "dropdown") {
        rightSideMarkup.push(renderDropdown(props.inputs[i], props));
      } else if (props.inputs[i].type === "multiselect") {
        rightSideMarkup.push(renderMultiSelect(props.inputs[i], props));
      } else if (props.inputs[i].type === "datetime") {
        rightSideMarkup.push(renderDatePicker(props.inputs[i], props));
      } else if (props.inputs[i].type === "time") {
        rightSideMarkup.push(renderTimePicker(props.inputs[i], props));
      } else if (props.inputs[i].type === "fileupload") {
        rightSideMarkup.push(renderFileUpload(props.inputs[i], props));
      }
    }
  }

  if (props.inputs.length % 2 === 0 || props.inputs.length > 6) {
    return (
      <div>
        <div className="row">
          <div className="col-sm-6">{leftSideMarkup.map((input) => input)}</div>
          <div className="col-sm-6">
            {rightSideMarkup.map((input) => input)}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row">
        <div className="col-sm-12">
          {leftSideMarkup.map((input) => input)}{" "}
          {rightSideMarkup.map((input) => input)}
        </div>
      </div>
    );
  }
};

export default sysForm;
