import React, { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./home";
import ChangePassword from "./changePassword";
import Notfound from "./notfound";
import ApplicationInfo from "../mainComponents/applicationInfo";
import UserManagement from "../contentComponents/system/usermanagement";
import RoleManagement from "../contentComponents/system/rolemanagement";
import SystemParameters from "../contentComponents/system/systemparameters";
import sysJwt from "../../utils/sysJwt";
import Reports from "../contentComponents/reports/reports";
import ExecuteReport from "../contentComponents/reports/executeReport";
import Faq from "../contentComponents/definitions/faq";
import Notification from "../contentComponents/definitions/notifications";
import Category from "../contentComponents/definitions/category";
import Subcategory from "../contentComponents/definitions/subcategory";
import Units from "../contentComponents/definitions/units";
import Owners from "../contentComponents/definitions/owners";
import ExpenseType from "../contentComponents/definitions/expenseTypes";
import Expenses from "../contentComponents/definitions/expenses";
import Installments from "../contentComponents/installments/installments";
import UnitInstallments from "../contentComponents/installments/unitInstallments";
import UnitServiceBills from "../contentComponents/definitions/serviceBills";
import ServicBills from "../contentComponents/definitions/serviceBills";
import ElectrictyVouchers from "../contentComponents/definitions/electricityVouchers";
import Payments from "../contentComponents/payments/payments";
import VouchersStock from "../contentComponents/electricity/voucherStock";
import TypeVocuhers from "../contentComponents/electricity/typeVouchers";
import VoucherTypes from "..//contentComponents/electricity/voucherTypes";
import Tickets from "../contentComponents/definitions/tickets";
import PayType from "../contentComponents/definitions/payTypes";
import UnitContracts from "../contentComponents/definitions/unitContracts";
import IncomeTypes from "../contentComponents/definitions/incomeTypes";
import Incomes from "../contentComponents/definitions/incomes";

class AppRoutes extends Component {
  state = {};
  render() {
    return (
      <Routes>
        {sysJwt.isAuthorized(["CAEC"]) && (
          <Route
            path="/reports"
            element={
              <Reports
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAEC", "CAF0"]) && (
          <Route
            path="/executereport"
            element={
              <ExecuteReport
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CABE"]) && (
          <Route
            path="/usermanagement"
            element={
              <UserManagement
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAC5"]) && (
          <Route
            path="/rolemanagement"
            element={
              <RoleManagement
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAD5"]) && (
          <Route
            path="/systemparameters"
            element={
              <SystemParameters
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB04"]) && (
          <Route
            path="/faq"
            element={
              <Faq
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB10"]) && (
          <Route
            path="/notification"
            element={
              <Notification
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB12"]) && (
          <Route
            path="/category"
            element={
              <Category
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB16"]) && (
          <Route
            path="/subcategory"
            element={
              <Subcategory
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB1A"]) && (
          <Route
            path="/units"
            element={
              <Units
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB1E"]) && (
          <Route
            path="/owners"
            element={
              <Owners
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB25"]) && (
          <Route
            path="/expensetypes"
            element={
              <ExpenseType
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB29"]) && (
          <Route
            path="/expenses"
            element={
              <Expenses
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB2D"]) && (
          <Route
            path="/installments"
            element={
              <Installments
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB2D"]) && (
          <Route
            path="/unitinstallments"
            element={
              <UnitInstallments
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB1A"]) && (
          <Route
            path="/unitservicebills"
            element={
              <ServicBills
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB1A"]) && (
          <Route
            path="/unitelectrictyvouchers"
            element={
              <ElectrictyVouchers
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB33"]) && (
          <Route
            path="/payments"
            element={
              <Payments
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB3A"]) && (
          <Route
            path="/vouchersstock"
            element={
              <VouchersStock
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB3A"]) && (
          <Route
            path="/typevouchers"
            element={
              <TypeVocuhers
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB37"]) && (
          <Route
            path="/vouchertypes"
            element={
              <VoucherTypes
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB3E"]) && (
          <Route
            path="/tickets"
            element={
              <Tickets
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB43"]) && (
          <Route
            path="/paytypes"
            element={
              <PayType
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB43"]) && (
          <Route
            path="/unitcontracts"
            element={
              <UnitContracts
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB47"]) && (
          <Route
            path="/incometypes"
            element={
              <IncomeTypes
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB4B"]) && (
          <Route
            path="/incomes"
            element={
              <Incomes
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        <Route
          path="/applicationinfo"
          element={
            <ApplicationInfo
              inModalMode={true}
              isLoading={this.props.isLoading}
              updateIsLoading={this.props.updateIsLoading}
            />
          }
        />
        <Route
          path="/changepassword"
          element={
            <ChangePassword
              inModalMode={true}
              isLoading={this.props.isLoading}
              updateIsLoading={this.props.updateIsLoading}
            />
          }
        />
        <Route
          path="/home"
          element={<Home updateIsLoading={this.props.updateIsLoading} />}
        />
        <Route path="/notfound" element={<Notfound />} />
        <Route
          path="*"
          element={<Navigate to="/dashboard/notfound" replace />}
        />
      </Routes>
    );
  }
}

export default AppRoutes;
