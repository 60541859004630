/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";

class Units extends Component {
    state = {
        data: {
            unitName: "",
            categoryId: "",
            subcategoryId: "",
            blockName: "",
            floorNum: "",
            ownerId: "",
            price: "",
            electricityType: "",
            serviceFee: "",
            calSrvcBills: null,
            elecConsumerId: "",
            meterGuid: ""
        },

        allCategory: [],
        allSubcategory: [],
        allOwner: [],
        allElectricityType: [],
        allTrueFalse: [
            { label: "true", value: true },
            { label: "false", value: false },
        ],

        contentHeader: "Units",
        addButtonLabel: "Add Unit",
        addButtonIcon: "fa fa-plus",

        dataUrl: "/api/data/GetUnits",
        singleItemUrl: "/api/data/GetUnit",
        addUrl: "/api/data/AddUnit",
        editUrl: "/api/data/EditUnit",
        deleteUrl: "/api/data/DeleteUnit",
        BlackListUrl: "/api/data/BlackListUnit",

        inEditMode: false,
        inModalMode: false,
        modalHeaderAdd: "Add Unit",
        modalSubmitButtonLabelAdd: "Add",
        modalSubmitButtonIconAdd: "fa fa-plus",
        modalHeaderEdit: "Edit Unit",
        modalSubmitButtonLabelEdit: "Edit",
        modalSubmitButtonIconEdit: "fa fa-edit",
        modalWidth: "50vw",

        editEntityId: 0,

        tableData: [],
        tableColumns: [
            {
                id: 1,
                label: "ID",
                field: "ID",
                width: "85px",
            },
            {
                id: 2,
                label: "Unit Name",
                field: "UNIT_NAME",
            },
            {
                id: 3,
                label: "Owner Name",
                field: "OWNER_NAME",
            },
            {
                id: 4,
                label: "Category",
                field: "CATEGORY",
            },
            {
                id: 5,
                label: "Subcategory",
                field: "SUBCATEGORY",
            },
            {
                id: 6,
                label: "Block",
                field: "BLOCK_NAME",
            },
            {
                id: 7,
                label: "Floor Number",
                field: "FLOOR_NUM",
            },
            {
                id: 8,
                label: "Electricty Type",
                field: "ELECTRICITY_TYPE",
            },
            {
                id: 9,
                label: "Calculate Service Bills",
                field: "CAL_SRVC_BILLS",
            },
            {
                id: 10,
                label: "Insert Date",
                field: "INSERT_DATE",
            },
            {
                id: 11,
                label: "Last Update",
                field: "LAST_UPDATE",
            },
            {
                id: 12,
                label: "Actions",
                field: "SYS_ACTINOS",
                width: "120px",
                maxResizeWidth: "120px",
                resizable: false,
                pinned: true,
                cellRenderer: ({ data }) => {
                    return (
                        <div id="outer">
                            {sysJwt.isAuthorized(["CB1C"]) && (
                                <div className="inner" title="Edit">
                                    <button
                                        className="dropdown-item btn-popup-menu"
                                        disabled={this.props.isLoading}
                                        onClick={() => {
                                            this.getEditFormData(data).then(() => {
                                                this.setState({
                                                    inEditMode: true,
                                                });
                                                this.showForm();
                                            });
                                        }}
                                    >
                                        <i className="fa fa-edit" />
                                    </button>
                                </div>
                            )}
                            {sysJwt.isAuthorized(["CB1D"]) && (
                                <div className="inner" title="Delete">
                                    <button
                                        className="dropdown-item btn-popup-menu"
                                        disabled={this.props.isLoading}
                                        onClick={() => this.handleDelete(data)}
                                    >
                                        <i className="fa fa-trash" />
                                    </button>
                                </div>
                            )}
                            <div className="inner" title="Show Service Bills">
                                <button
                                    className="dropdown-item btn-popup-menu"
                                    disabled={this.props.isLoading}
                                    onClick={() =>
                                        window.location.replace(
                                            "/dashboard/unitservicebills?unitId=" +
                                            data.ID +
                                            "&unitName=" +
                                            data.UNIT_NAME
                                        )
                                    }
                                >
                                    <i className="fa fa-file-text" />
                                </button>
                            </div>
                            {data.ELECTRICITY_TYPE === "Voucher" ? (
                                <div className="inner" title="Show Electricty Vouchers">
                                    <button
                                        className="dropdown-item btn-popup-menu"
                                        disabled={this.props.isLoading}
                                        onClick={() =>
                                            window.location.replace(
                                                "/dashboard/unitelectrictyvouchers?unitId=" +
                                                data.ID +
                                                "&unitName=" +
                                                data.UNIT_NAME
                                            )
                                        }
                                    >
                                        <i className="fa fa-plug" />
                                    </button>
                                </div>
                            ) : (
                                ""
                            )}

                            <div className="inner" title="Unit Contract">
                                <button
                                    className="dropdown-item btn-popup-menu"
                                    disabled={this.props.isLoading}
                                    onClick={() =>
                                        window.location.replace(
                                            "/dashboard/unitcontracts?unitId=" +
                                            data.ID +
                                            "&unitName=" +
                                            data.UNIT_NAME
                                        )
                                    }
                                >
                                    <i className="fa fa-book" />
                                </button>
                            </div>
                            {sysJwt.isAuthorized(["CB50"]) && (
                                <div className="inner" title={`${data.BLACKLIST ? 'Remove from' : 'Add to'} BlackList`}>
                                    <button
                                        className="dropdown-item btn-popup-menu"
                                        disabled={this.props.isLoading}
                                        onClick={() => this.handleBlackListUnit(data)}
                                    >
                                        <i class="fa fa-ban" style={{ color: data.BLACKLIST ? 'red' : 'inherit' }}
                                            aria-hidden="true"></i>
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        ],
    };

    componentDidMount() {
        this.getData();
        this.getAllCategory();
        this.getAllOwner();
        this.getAllElectricityTypes();
    }

    getAllElectricityTypes() {
        post(
            "/api/data/GetAllElectricityTypes",
            null,
            this.props.updateIsLoading
        ).then((apiResponse) => {
            this.setState({
                allElectricityType: apiResponse.data.respData.data,
            });
        });
    }

    getAllCategory() {
        post("/api/data/getallcategories", null, this.props.updateIsLoading).then(
            (apiResponse) => {
                this.setState({
                    allCategory: apiResponse.data.respData.data,
                });
            }
        );
    }

    getAllSubcategory(categoryId) {
        post(
            "/api/data/getallsubcategories",
            { categoryId },
            this.props.updateIsLoading
        ).then((apiResponse) => {
            this.setState({
                allSubcategory: apiResponse.data.respData.data,
            });
        });
    }

    getAllOwner() {
        post("/api/data/getallowner", null, this.props.updateIsLoading).then(
            (apiResponse) => {
                this.setState({
                    allOwner: apiResponse.data.respData.data,
                });
            }
        );
    }

    updateForm = (inputId, inputValue) => {
        var data = this.state.data;
        data[inputId] = inputValue;
        this.setState({ data });
    };

    showForm() {
        this.setState({ inModalMode: true });
    }

    closeModal() {
        var data = this.state.data;

        Object.keys(data).forEach(function (key) {
            Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
        });

        this.setState({
            inModalMode: false,
            inEditMode: false,
            data: data,
            editEntityId: 0,
            allClaims: [],
        });

        this.getData();
    }

    getData() {
        post(this.state.dataUrl, null, this.props.updateIsLoading).then(
            (apiResponse) => {
                this.setState({ tableData: apiResponse.data.respData.data });
            }
        );
    }

    getEditFormData(row) {
        return post(
            this.state.singleItemUrl,
            { id: row.ID },
            this.props.updateIsLoading
        ).then((apiResponse) => {
            var data = this.state.data;

            data["unitName"] = apiResponse.data.respData.data[0].UNIT_NAME;
            data["categoryId"] = apiResponse.data.respData.data[0].CATEGORY_ID;
            data["subcategoryId"] = apiResponse.data.respData.data[0].SUBCATEGORY_ID;
            data["blockName"] = apiResponse.data.respData.data[0].BLOCK_NAME;
            data["floorNum"] = apiResponse.data.respData.data[0].FLOOR_NUM;
            data["ownerId"] = apiResponse.data.respData.data[0].OWNERS_ID;
            data["price"] = apiResponse.data.respData.data[0].PRICE;
            data["electricityType"] =
                apiResponse.data.respData.data[0].ELECTRICITY_TYPE;
            data["serviceFee"] = apiResponse.data.respData.data[0].SERVICE_FEE;
            data["calSrvcBills"] = apiResponse.data.respData.data[0].CAL_SRVC_BILLS;
            data["elecConsumerId"] =
                apiResponse.data.respData.data[0].ELEC_CONSUMER_ID;
            data["meterGuid"] =
                apiResponse.data.respData.data[0]?.METER_GUID;
            this.setState({
                data: data,
                editEntityId: apiResponse.data.respData.data[0].ID,
            });
        });
    }

    handleDelete(row) {
        confirmAlert({
            title: "Delete Unit",
            message: "Are you sure to delete this unit?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        post(
                            this.state.deleteUrl,
                            { id: row.ID },
                            this.props.updateIsLoading
                        ).then((apiResponse) => {
                            apiResponse.data.respCode === 0 ? this.getData() : null;
                        });
                    },
                },
                {
                    label: "Cancel",
                    onClick: () => null,
                },
            ],
        });
    }
    handleBlackListUnit(row) {
        confirmAlert({
            title: "BlackList Unit",
            message: `Are you sure to ${row.BLACKLIST ? 'Remove from' : 'Add to'} BlackList this unit?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        post(
                            this.state.BlackListUrl,
                            { id: row.ID },
                            this.props.updateIsLoading
                        ).then((apiResponse) => {
                            apiResponse.data.respCode === 0 ? this.getData() : null;
                        });
                    },
                },
                {
                    label: "Cancel",
                    onClick: () => null,
                },
            ],
        });
    }

    doSubmit() {
        const submitData = Object.assign({}, this.state.data);

        if (this.state.inEditMode) {
            submitData["id"] = this.state.editEntityId;
        }
        post(
            this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
            submitData,
            this.props.updateIsLoading
        ).then((apiResponse) => {
            if (apiResponse.data.respCode === 0) {
                this.props.updateIsLoading(false);
                this.closeModal();
            }
        });
    }

    render() {
        return (
            <div className="bms-content-component fade-in">
                <div className="row">
                    <div className="col">
                        <h4>{this.state.contentHeader}</h4>
                    </div>
                    <div className="col flex-container-left">
                        {sysJwt.isAuthorized(["CB1B"]) && (
                            <Button
                                label={this.state.addButtonLabel}
                                icon={this.state.addButtonIcon}
                                className="p-button-rounded p-button-secondary"
                                disabled={this.props.isLoading}
                                onClick={() => {
                                    this.setState({ inEditMode: false });
                                    this.showForm();
                                }}
                            />
                        )}
                    </div>
                </div>
                <hr />
                <Dialog
                    visible={this.state.inModalMode}
                    style={{ width: this.state.modalWidth }}
                    onHide={(e) => this.closeModal()}
                    dismissableMask={true}
                    closable={false}
                    header={
                        <h5 className="modal-header">
                            {this.state.inEditMode
                                ? this.state.modalHeaderEdit
                                : this.state.modalHeaderAdd}
                        </h5>
                    }
                    footer={
                        <div>
                            <Button
                                label={
                                    this.state.inEditMode
                                        ? this.state.modalSubmitButtonLabelEdit
                                        : this.state.modalSubmitButtonLabelAdd
                                }
                                icon={
                                    this.state.inEditMode
                                        ? this.state.modalSubmitButtonIconEdit
                                        : this.state.modalSubmitButtonIconAdd
                                }
                                className="p-button-rounded p-button-secondary"
                                disabled={this.props.isLoading}
                                onClick={() => this.doSubmit()}
                            />
                            <Button
                                label="Cancel"
                                icon="fa fa-close"
                                className="p-button-rounded p-button-danger"
                                onClick={() => this.closeModal()}
                            />
                        </div>
                    }
                >
                    <SysForm
                        isLoading={this.props.isLoading}
                        inEditMode={this.state.inEditMode}
                        data={this.state.data}
                        updateForm={this.updateForm}
                        inputs={[
                            {
                                type: "text",
                                id: "unitName",
                                label: "Unit Name",
                                disabled: false,
                            },
                            {
                                type: "dropdown",
                                id: "categoryId",
                                label: "Category",
                                options: this.state.allCategory,
                                disabled: false,
                                customOnChangeHandler: (categoryId) => {
                                    this.getAllSubcategory(categoryId);
                                },
                            },
                            {
                                type: "dropdown",
                                id: "subcategoryId",
                                label: "Subcategory",
                                options: this.state.allSubcategory,
                                disabled: false,
                            },
                            {
                                type: "text",
                                id: "blockName",
                                label: "Block",
                                disabled: false,
                            },
                            {
                                type: "text",
                                id: "floorNum",
                                label: "Floor Number",
                                disabled: false,
                            },
                            {
                                type: "dropdown",
                                id: "ownerId",
                                label: "Owner",
                                options: this.state.allOwner,
                                disabled: false,
                            },
                            {
                                type: "text",
                                id: "price",
                                label: "Price",
                                disabled: false,
                            },
                            {
                                type: "dropdown",
                                id: "electricityType",
                                label: "Electricity Type",
                                options: this.state.allElectricityType,
                                disabled: false,
                                tooltip:
                                    "Keep it null to take same value of the chosen category",
                            },
                            {
                                type: "text",
                                id: "serviceFee",
                                label: "Service Fee",
                                disabled: false,
                                tooltip:
                                    "An amount is entered in case that the calculation of the amount is different from the service fees in the subcategory",
                            },
                            {
                                type: "dropdown",
                                id: "calSrvcBills",
                                label: "Calculate Service Bills",
                                options: this.state.allTrueFalse,
                                disabled: false,
                                tooltip:
                                    "When set true, will calculate service bill at the beginning of each month",
                            },
                            {
                                type: "text",
                                id: "elecConsumerId",
                                label: "Electricity Consumer ID",
                                disabled:
                                    this.state.data.electricityType === "Balance" ? false : true,
                            },
                            {
                                type: "text",
                                id: "meterGuid",
                                label: "Meter Guid",
                                disabled:
                                    this.state.data.electricityType === "Balance" ? true : false,
                            }
                        ]}
                    />
                </Dialog>
                <GridTable
                    columns={this.state.tableColumns}
                    rows={this.state.tableData}
                    pageSizes={[10, 15, 20, 25, 30]}
                    texts={{
                        search: "Search:",
                        totalRows: "Total Rows:",
                        rows: "Row:",
                        selected: "Selected",
                        rowsPerPage: "Rows Per Page:",
                        page: "Page:",
                        of: "of",
                        prev: "Pervious",
                        next: "Next",
                        columnVisibility: "Column Visibility",
                    }}
                />
            </div>
        );
    }
}

export default Units;
