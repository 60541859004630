/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { toastError } from "../../../utils/sysToast";
import InfoPopUp from "../../commonComponents/infoPopUp";
import {
    buyVoucherPrint,
    payBillPrint,
    otherPaymentPrint,
} from "../../../utils/printFroms";
import { ExportSheet } from "react-xlsx-sheet";
import XLSX from "xlsx";

class Payments extends Component {
    state = {
        data: {
            unitId: "",
            payDate: "",
            bills: [],
            amount: "",
            payTypeId: "",
            discountType: "",
            discountAmount: "",
        },

        Username: "",
        allPayTypes: [],
        allPayMethods: [],
        allUnits: [],
        allUnitsOwner: [],
        allInStockVouchers: [],
        allUnitUnpaidBills: [],
        allUnitsOwnerSlava: [],
        allUnitsSlava: [],
        allUnitsOwnerMiniSlava: [],
        allDiscountTypes: [
            { label: "Amount", value: "Amount" },
            { label: "Percentage", value: "Percentage" },
        ],
        payTypes: [],
        payDate: "",
        dates: "",
        payType: "",
        payMethod: "",
        unit: "",
        totalAmountToPay: "",

        addButtonIcon: "fa fa-plus",
        modalWidth: "50vw",
        contentHeader: "Payments",

        inEditMode: false,
        inModalMode: false,

        inModalModePay: false,
        inModalModeCharge: false,
        inModalModeOtherPayment: false,

        dataUrl: "/api/data/GetPayments",

        editEntityId: 0,

        xlsxHeaders: [],
        tableData: [],
        totalAmountSearch: "",
        tableColumns: [
            {
                id: 1,
                field: "ID",
                label: "ID",
                width: "75px",
            },
            {
                id: 2,
                label: "Pay Date",
                field: "PAY_DATE",
            },
            {
                id: 3,
                label: "Amount",
                field: "PAY_AMOUNT",
            },
            {
                id: 4,
                label: "Pay Type",
                field: "TYPE",
            },
            {
                id: 5,
                label: "Pay Method",
                field: "PAY_METOD",
            },
            {
                id: 6,
                label: "Unit",
                field: "UNIT_NAME",
            },
            {
                id: 7,
                label: "Owner",
                field: "FULL_NAME",
            },
            {
                id: 8,
                label: "Insert Date",
                field: "INSERT_DATE",
            },
            {
                id: 9,
                label: "Last Update",
                field: "LAST_UPDATE",
            },
            {
                id: 10,
                label: "Actions",
                field: "SYS_ACTINOS",
                width: "100px",
                maxResizeWidth: "100px",
                resizable: false,
                pinned: true,
                cellRenderer: ({ data }) => {
                    return (
                        <div id="outer">
                            <div className="inner" title="Payment Order Info">
                                <button
                                    className="dropdown-item btn-popup-menu"
                                    disabled={this.props.isLoading}
                                    onClick={() => {
                                        this.handleMoreInfo(data);
                                    }}
                                >
                                    <i className="fa fa-info" />
                                </button>
                            </div>
                            <div className="inner" title="Print">
                                <button
                                    className="dropdown-item btn-popup-menu"
                                    disabled={this.props.isLoading}
                                    onClick={() => {
                                        this.handlePrint(data);
                                    }}
                                >
                                    <i className="fa fa-print" />
                                </button>
                            </div>
                        </div>
                    );
                },
            },
        ],
    };

    handlePrint(row) {
        console.log(row);
        if (row.TYPE === "Electricity" || row.TYPE == "Gen .Electricity") {
            buyVoucherPrint(
                row.ID,
                row.PAY_METOD,
                row.UNIT_NAME,
                row.FULL_NAME,
                row.ELEC_VOUC_TYPE ?? row.TYPE,
                row.PAY_AMOUNT,
                row.serialNumber,
                row.PAY_DATE,
                row.SYS_USERNAME,
                row.KW_RATE,
                row.USD_AMOUNT,
            );
        } else if (row.TYPE === "Service Fee") {
            post(
                "/api/data/GetPrintBill",
                {
                    id: row.ID,
                },
                this.props.updateIsLoading
            ).then((apiResponse) => {
                if (apiResponse.data.respCode === 0) {
                    console.log(apiResponse.data.respData);
                    payBillPrint(
                        apiResponse.data.respData.paidBills,
                        apiResponse.data.respData.totalAmount,
                        apiResponse.data.respData.username,
                        apiResponse.data.respData.discountType,
                        apiResponse.data.respData.discountAmount,
                        apiResponse.data.respData.paidAmount
                    );
                }
            });
        } else {
            otherPaymentPrint(
                row.ID,
                row.PAY_METOD,
                row.UNIT_NAME,
                row.FULL_NAME,
                row.PAY_AMOUNT,
                row.TYPE,
                row.PAY_DATE,
                row.SYS_USERNAME
            );
        }
    }

    handleMoreInfo(row) {
        const jsonData = {
            "Total Amount Without Discount": row.PAY_AMOUNT_WITHOUT_DISCOUNT,
            "Discount Type": row.DISCOUNT_TYPE,
            "Discount Amount": row.DISCOUNT_AMOUNT,
            "Electricity Voucher Type": row.ELEC_VOUC_TYPE,
            "Electricity Voucher ID": row.ELEC_VOUC_ID,
            "Service Bill": row.SRVC_BILL_ID,
            "Gateway Response": row.GET_STATUS_RESP,
            "Electricity Type": row.UNIT_ELEC_TYPE,
            "Electricity Trans Status": row.ELEC_SYSTEM_TRANS_STATUS,
            "Electricity System Resp": row.ELEC_SYSTEM_RESP,
            Username: row.SYS_USERNAME,
        };

        confirmAlert({
            title: row.ID,
            childrenElement: () => (
                <InfoPopUp
                    jsonData={jsonData}
                    titlesColumnSize={4}
                    valuesColumnSize={8}
                    popUpWidth="1000px"
                />
            ),
            buttons: [
                {
                    label: "Close",
                    onClick: () => {
                        null;
                    },
                },
            ],
        });
    }

    updateForm = (inputId, inputValue) => {
        var data = this.state.data;
        data[inputId] = inputValue;
        this.setState({ data });
    };

    showForm() {
        this.setState({ inModalMode: true });
    }

    showFormPayBill() {
        this.setState({ inModalModePay: true });
    }

    showFormOtherPayment() {
        this.setState({ inModalModeOtherPayment: true });
    }

    showFormCharge() {
        this.setState({ inModalModeCharge: true });
    }

    closeModal() {
        var data = this.state.data;

        Object.keys(data).forEach(function (key) {
            Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
        });

        this.setState({
            inModalMode: false,
            inModalModePay: false,
            inModalModeCharge: false,
            inModalModeOtherPayment: false,
            data: data,
        });
    }

    componentDidMount() {
        this.getAllPayTypes();
        this.getAllPayMethods();
        this.getAllUnits();
        this.getAllUnitsOwner();
        this.getAllUnitsOwnerSlava();
        this.getAllUnitsOwnerMiniSlava();
        this.getPayTypes();
        this.setState({
            Username: sysJwt.getUsername(),
        });
    }

    getAllPayTypes() {
        post("/api/data/GetAllPayType", null, this.props.updateIsLoading).then(
            (apiResponse) => {
                this.setState({
                    allPayTypes: apiResponse.data.respData.data,
                });
            }
        );
    }

    getPayTypes() {
        post("/api/data/GetPayTypes", null, this.props.updateIsLoading).then(
            (apiResponse) => {
                this.setState({
                    payTypes: apiResponse.data.respData.data,
                });
            }
        );
    }

    getAllPayMethods() {
        post("/api/data/GetAllPayMethod", null, this.props.updateIsLoading).then(
            (apiResponse) => {
                this.setState({
                    allPayMethods: apiResponse.data.respData.data,
                });
            }
        );
    }

    getAllUnits() {
        post("/api/data/GetAllUnits", null, this.props.updateIsLoading).then(
            (apiResponse) => {
                this.setState({
                    allUnits: apiResponse.data.respData.data,
                });
            }
        );
    }

    getAllUnitsOwner() {
        post("/api/data/GetAllUnitsOwner", null, this.props.updateIsLoading).then(
            (apiResponse) => {
                this.setState({
                    allUnitsOwner: apiResponse.data.respData.data,
                });
            }
        );
    }

    getAllUnitsOwnerSlava() {
        post(
            "/api/data/GetAllUnitsOwnerSlava",
            null,
            this.props.updateIsLoading
        ).then((apiResponse) => {
            this.setState({
                allUnitsOwnerSlava: apiResponse.data.respData.data,
                allUnitsSlava: apiResponse.data.respData.data?.filter(x => x.ELECTRICITY_TYPE == 'Voucher')
            });
        });
    }

    getAllUnitsOwnerMiniSlava() {
        post(
            "/api/data/GetAllUnitsOwnerMiniSlava",
            null,
            this.props.updateIsLoading
        ).then((apiResponse) => {
            this.setState({
                allUnitsOwnerMiniSlava: apiResponse.data.respData.data,
            });
        });
    }

    getAllInStockVouchers(unitId) {
        post(
            "/api/data/GetInStockVoucherTypes",
            { unitId: unitId },
            this.props.updateIsLoading
        ).then((apiResponse) => {
            this.setState({
                allInStockVouchers: apiResponse.data.respData.data,
            });
        });
    }

    getUnitUnpaidBills(unitId) {
        post(
            "/api/data/GetUnitUnpaidBills",
            { unitId: unitId },
            this.props.updateIsLoading
        ).then((apiResponse) => {
            this.setState({
                allUnitUnpaidBills: apiResponse.data.respData.data,
            });
        });
    }

    doBuyVoucher() {
        post(
            "/api/data/BuyVoucherCash",
            {
                //typeId: this.state.data.typeId,
                amount: this.state.data.amount,
                unitId: this.state.data.unitId,
            },
            this.props.updateIsLoading
        ).then((apiResponse) => {
            console.log(apiResponse);
            if (apiResponse.data.respCode === 0) {
                this.props.updateIsLoading(false);
                this.closeModal();
                buyVoucherPrint(
                    apiResponse.data.respData.paymentId,
                    apiResponse.data.respData.paymentMethod,
                    apiResponse.data.respData.unitName,
                    apiResponse.data.respData.ownerName,
                    //apiResponse.data.respData.voucherType,
                    "",
                    apiResponse.data.respData.price,
                    apiResponse.data.respData.serialNumber,
                    apiResponse.data.respData.payDate,
                    apiResponse.data.respData.username,
                    apiResponse.data.respData.kwRate,
                    "",
                );
            }
        });
    }

    doOtherPayment() {
        post(
            "/api/data/PayUnitByType",
            {
                payTypeId: this.state.data.payTypeId,
                unitId: this.state.data.unitId,
                amount: this.state.data.amount,
            },
            this.props.updateIsLoading
        ).then((apiResponse) => {
            if (apiResponse.data.respCode === 0) {
                this.props.updateIsLoading(false);
                this.closeModal();
                otherPaymentPrint(
                    apiResponse.data.respData.paymentId,
                    apiResponse.data.respData.paymentMethod,
                    apiResponse.data.respData.unitName,
                    apiResponse.data.respData.ownerName,
                    apiResponse.data.respData.price,
                    apiResponse.data.respData.payType,
                    apiResponse.data.respData.payDate,
                    apiResponse.data.respData.username
                );
            }
        });
    }

    doCharge() {
        post(
            "/api/data/ChargeElectricity",
            {
                unitId: this.state.data.unitId,
                amount: this.state.data.amount,
            },
            this.props.updateIsLoading
        ).then((apiResponse) => {
            console.log(apiResponse);
            if (apiResponse.data.respCode === 0) {
                this.props.updateIsLoading(false);
                this.closeModal();
                debugger
                buyVoucherPrint(
                    apiResponse.data.respData.paymentId,
                    apiResponse.data.respData.paymentMethod,
                    apiResponse.data.respData.unitName,
                    apiResponse.data.respData.ownerName,
                    "",
                    apiResponse.data.respData.price,
                    "",
                    apiResponse.data.respData.payDate,
                    apiResponse.data.respData.username,
                    apiResponse.data.respData.kwRate,
                    apiResponse.data.respData.usdAmount
                );
            }
        });
    }

    doPayBills() {
        post(
            "/api/data/PayUnitBillsCash",
            {
                bills: this.state.data.bills,
                discountType: this.state.data.discountType,
                discountAmount: this.state.data.discountAmount,
            },
            this.props.updateIsLoading
        ).then((apiResponse) => {
            console.log(apiResponse);
            if (apiResponse.data.respCode === 0) {
                this.props.updateIsLoading(false);
                this.closeModal();
                payBillPrint(
                    apiResponse.data.respData.paidBills,
                    apiResponse.data.respData.totalAmount,
                    apiResponse.data.respData.username,
                    apiResponse.data.respData.discountType,
                    apiResponse.data.respData.discountAmount,
                    apiResponse.data.respData.paidAmount
                );
            }
        });
    }

    getData() {
        if (!this.state.dates[0] || !this.state.dates[1]) {
            toastError("Please select DateFrom and DateTo");
        } else {
            post(
                this.state.dataUrl,
                {
                    dateFrom: this.state.dates[0],
                    dateTo: this.state.dates[1],
                    unitId: this.state.unit,
                    payType: this.state.payType,
                    payMethod: this.state.payMethod,
                },
                this.props.updateIsLoading
            ).then((apiResponse) => {
                this.setState({
                    tableData: apiResponse.data.respData.data,
                    totalAmountSearch: apiResponse.data.respData.totalAmount,
                });
                this.buildReportTable(apiResponse.data.respData.data);
            });
        }
    }

    buildReportTable(reportData) {
        let xlsxHeaders = [];
        let co = 1;
        if (reportData.length !== 0) {
            for (var property in reportData[0]) {
                if (reportData[0].hasOwnProperty(property)) {
                    co++;
                    xlsxHeaders.push({ title: property, dataIndex: property });
                }
            }
            this.setState({
                xlsxHeaders: xlsxHeaders,
            });
        }
    }

    sumTotalBills(bills) {
        var sum = 0;
        for (var i = 0; i < bills.length; i++) {
            for (var j = 0; j < this.state.allUnitUnpaidBills.length; j++) {
                if (bills[i] === this.state.allUnitUnpaidBills[j].value) {
                    sum = sum + this.state.allUnitUnpaidBills[j].dueAmount;
                }
            }
        }
        this.setState({ totalAmountToPay: sum });
    }

    render() {
        return (
            <div className="bms-content-component fade-in">
                <div className="row">
                    <div className="col">
                        <h4>{this.state.contentHeader}</h4>
                    </div>
                    <div className="col flex-container-left">
                        Date:
                        <Calendar
                            selectionMode="range"
                            showIcon="true"
                            value={this.state.dates}
                            className="flex-item-left mr-2"
                            onChange={(e) => this.setState({ dates: e.value })}
                            style={{ width: "250px" }}
                        ></Calendar>
                        Pay Type:
                        <Dropdown
                            value={this.state.payType}
                            showClear={true}
                            options={this.state.allPayTypes}
                            filter={true}
                            onChange={(e) => this.setState({ payType: e.target.value })}
                        />
                        &nbsp; Pay Method:
                        <Dropdown
                            value={this.state.payMethod}
                            showClear={true}
                            options={this.state.allPayMethods}
                            filter={true}
                            onChange={(e) => this.setState({ payMethod: e.target.value })}
                        />
                        &nbsp; Unit:
                        <Dropdown
                            value={this.state.unit}
                            showClear={true}
                            options={this.state.allUnits}
                            filter={true}
                            onChange={(e) => this.setState({ unit: e.target.value })}
                        />
                        &nbsp;
                        <Button
                            label="Search "
                            className="p-button-rounded p-button-secondary flex-item-left mr-2"
                            disabled={this.props.isLoading}
                            onClick={() => {
                                this.getData();
                            }}
                        />
                        &nbsp;
                        {sysJwt.isAuthorized(["CB35"]) && (
                            <Button
                                label="BuyVoucher "
                                className="p-button-rounded p-button-secondary flex-item-left mr-2"
                                disabled={this.props.isLoading}
                                onClick={() => {
                                    this.showForm();
                                }}
                            />
                        )}
                        &nbsp;
                        {sysJwt.isAuthorized(["CB41"]) && (
                            <Button
                                label="ChargeElectricity "
                                className="p-button-rounded p-button-secondary flex-item-left mr-2"
                                disabled={this.props.isLoading}
                                onClick={() => {
                                    this.showFormCharge();
                                }}
                            />
                        )}
                        &nbsp;
                        {sysJwt.isAuthorized(["CB34"]) && (
                            <Button
                                label="PayBill "
                                className="p-button-rounded p-button-secondary flex-item-left mr-2"
                                disabled={this.props.isLoading}
                                onClick={() => {
                                    this.showFormPayBill();
                                }}
                            />
                        )}
                        &nbsp;
                        {sysJwt.isAuthorized(["CB42"]) && (
                            <Button
                                label="OtherPayment "
                                className="p-button-rounded p-button-secondary flex-item-left mr-2"
                                disabled={this.props.isLoading}
                                onClick={() => {
                                    this.showFormOtherPayment();
                                }}
                            />
                        )}
                    </div>
                </div>
                <hr />
                <Dialog
                    visible={this.state.inModalMode}
                    style={{ width: this.state.modalWidth }}
                    onHide={(e) => this.closeModal()}
                    dismissableMask={true}
                    closable={false}
                    header={<h5 className="modal-header">Buy Voucher Cash</h5>}
                    footer={
                        <div>
                            <Button
                                label="Buy"
                                icon={this.state.modalSubmitButtonIconAdd}
                                className="p-button-rounded p-button-secondary"
                                disabled={this.props.isLoading}
                                onClick={() => this.doBuyVoucher()}
                            />
                            <Button
                                label="Cancel"
                                icon="fa fa-close"
                                className="p-button-rounded p-button-danger"
                                onClick={() => this.closeModal()}
                            />
                        </div>
                    }
                >
                    <SysForm
                        isLoading={this.props.isLoading}
                        inEditMode={this.state.inEditMode}
                        data={this.state.data}
                        updateForm={this.updateForm}
                        inputs={[
                            {
                                type: "dropdown",
                                id: "unitId",
                                label: "Unit Name",
                                disabled: false,
                                options: this.state.allUnitsSlava,
                                //customOnChangeHandler: (unitId) => {
                                //    this.getAllInStockVouchers(unitId);
                                //},
                            },
                            //{
                            //    type: "dropdown",
                            //    id: "typeId",
                            //    label: "Voucher Tpye",
                            //    disabled: false,
                            //    options: this.state.allInStockVouchers,
                            //},
                            {
                                type: "text",
                                id: "amount",
                                label: "Amount ($)",
                                disabled: false,
                            },
                        ]}
                    />
                </Dialog>
                <Dialog
                    visible={this.state.inModalModeOtherPayment}
                    style={{ width: this.state.modalWidth }}
                    onHide={(e) => this.closeModal()}
                    dismissableMask={true}
                    closable={false}
                    header={<h5 className="modal-header">Other Payments</h5>}
                    footer={
                        <div>
                            <Button
                                label="Pay"
                                icon={this.state.modalSubmitButtonIconAdd}
                                className="p-button-rounded p-button-secondary"
                                disabled={this.props.isLoading}
                                onClick={() => this.doOtherPayment()}
                            />
                            <Button
                                label="Cancel"
                                icon="fa fa-close"
                                className="p-button-rounded p-button-danger"
                                onClick={() => this.closeModal()}
                            />
                        </div>
                    }
                >
                    <SysForm
                        isLoading={this.props.isLoading}
                        inEditMode={this.state.inEditMode}
                        data={this.state.data}
                        updateForm={this.updateForm}
                        inputs={[
                            {
                                type: "dropdown",
                                id: "unitId",
                                label: "Unit Name",
                                disabled: false,
                                options: this.state.allUnits,
                            },
                            {
                                type: "text",
                                id: "amount",
                                label: "Payment Amount",
                                disabled: false,
                            },
                            {
                                type: "dropdown",
                                id: "payTypeId",
                                label: "Payment Type",
                                disabled: false,
                                options: this.state.payTypes,
                            },
                        ]}
                    />
                </Dialog>
                <Dialog
                    visible={this.state.inModalModeCharge}
                    style={{ width: this.state.modalWidth }}
                    onHide={(e) => this.closeModal()}
                    dismissableMask={true}
                    closable={false}
                    header={<h5 className="modal-header">Charge Electricity</h5>}
                    footer={
                        <div>
                            <Button
                                label="Charge"
                                icon={this.state.modalSubmitButtonIconAdd}
                                className="p-button-rounded p-button-secondary"
                                disabled={this.props.isLoading}
                                onClick={() => this.doCharge()}
                            />
                            <Button
                                label="Cancel"
                                icon="fa fa-close"
                                className="p-button-rounded p-button-danger"
                                onClick={() => this.closeModal()}
                            />
                        </div>
                    }
                >
                    <SysForm
                        isLoading={this.props.isLoading}
                        inEditMode={this.state.inEditMode}
                        data={this.state.data}
                        updateForm={this.updateForm}
                        inputs={[
                            {
                                type: "text",
                                id: "amount",
                                label: "Charge Amount IQD",
                                disabled: false,
                            },
                            {
                                type: "dropdown",
                                id: "unitId",
                                label: "Unit Name",
                                disabled: false,
                                options: this.state.allUnitsOwnerMiniSlava,
                            },
                        ]}
                    />
                </Dialog>
                <Dialog
                    visible={this.state.inModalModePay}
                    style={{ width: this.state.modalWidth }}
                    onHide={(e) => this.closeModal()}
                    dismissableMask={true}
                    closable={false}
                    header={<h5 className="modal-header">Pay Bill Cash</h5>}
                    footer={
                        <div>
                            <Button
                                label="Pay"
                                icon={this.state.modalSubmitButtonIconAdd}
                                className="p-button-rounded p-button-secondary"
                                disabled={this.props.isLoading}
                                onClick={() => this.doPayBills()}
                            />
                            <Button
                                label="Cancel"
                                icon="fa fa-close"
                                className="p-button-rounded p-button-danger"
                                onClick={() => this.closeModal()}
                            />
                        </div>
                    }
                >
                    <SysForm
                        isLoading={this.props.isLoading}
                        inEditMode={this.state.inEditMode}
                        data={this.state.data}
                        updateForm={this.updateForm}
                        inputs={[
                            {
                                type: "dropdown",
                                id: "unitId",
                                label: "Unit Name",
                                disabled: false,
                                options: this.state.allUnitsOwner,
                                customOnChangeHandler: (unitId) => {
                                    this.getUnitUnpaidBills(unitId);
                                },
                            },
                            {
                                type: "multiselect",
                                id: "bills",
                                label: "Bill to Pay",
                                disabled: false,
                                options: this.state.allUnitUnpaidBills,
                                customOnChangeHandler: (bills) => {
                                    this.sumTotalBills(bills);
                                },
                            },
                            {
                                type: "dropdown",
                                id: "discountType",
                                label: "Discount Type",
                                disabled: sysJwt.isAuthorized(["CB4F"]) ? false : true,
                                options: this.state.allDiscountTypes,
                            },
                            {
                                type: "text",
                                id: "discountAmount",
                                label: "Discount Value",
                                disabled: sysJwt.isAuthorized(["CB4F"]) ? false : true,
                            },
                        ]}
                    />
                    <span style={{ color: "black" }}>
                        Total Amount to pay: {this.state.totalAmountToPay}
                    </span>
                    <br />
                    <span style={{ color: "black" }}>
                        Net Amount to pay:{" "}
                        {this.state.data.discountType && this.state.data.discountAmount
                            ? this.state.data.discountType === "Amount"
                                ? this.state.totalAmountToPay - this.state.data.discountAmount
                                : this.state.totalAmountToPay -
                                (this.state.totalAmountToPay *
                                    this.state.data.discountAmount) /
                                100
                            : this.state.totalAmountToPay}
                    </span>
                </Dialog>
                <GridTable
                    columns={this.state.tableColumns}
                    rows={this.state.tableData}
                    pageSizes={[10, 15, 20, 25, 30]}
                    texts={{
                        search: "Search:",
                        totalRows: "Total Rows:",
                        rows: "Row:",
                        selected: "Selected",
                        rowsPerPage: "Rows Per Page:",
                        page: "Page:",
                        of: "of",
                        prev: "Pervious",
                        next: "Next",
                        columnVisibility: "Column Visibility",
                    }}
                />
                <div className="row">
                    <div className="col-10">
                        <span style={{ color: "black" }}>
                            Total Amount: <b>{this.state.totalAmountSearch}</b>
                        </span>
                    </div>
                    <div className="col-2">
                        {this.state.tableData.length > 0 ? (
                            <ExportSheet
                                header={this.state.xlsxHeaders}
                                dataSource={this.state.tableData}
                                fileName="ExportPayments"
                                isRequiredNameDate={false}
                                xlsx={XLSX}
                            >
                                <Button
                                    className="p-button-rounded p-button-success"
                                    label="Export Result Excel"
                                    icon="fa fa-file-excel-o"
                                />
                            </ExportSheet>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Payments;
