/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import queryString from "query-string";
import InfoPopUp from "../../commonComponents/infoPopUp";

class TypeVocuhers extends Component {
  state = {
    data: {
      numberPayments: "",
      paymentAmount: "",
      periodOfPayment: "",
      startDate: "",
      amount: "",
      dueDate: "",
      payDate: "",
    },

    queryString: queryString.parse(window.location.search),

    contentHeader:
      "Type Vouchers - " +
      queryString.parse(window.location.search).typeName +
      " - " +
      queryString.parse(window.location.search).unitName,

    dataUrl: "/api/data/GeTypesElectricityVouchers",

    tableData: [],
    billId: "",

    tableColumns: [
      {
        id: 1,
        field: "ID",
        label: "ID",
        width: "75px",
      },
      {
        id: 2,
        field: "INSERT_DATE",
        label: "Insert Date",
      },
      {
        id: 3,
        field: "IS_BUYED",
        label: "Is Buyed",
        width: "100px",
      },
      {
        id: 4,
        field: "BUY_DATE",
        label: "Buy Date",
      },
      {
        id: 5,
        field: "UNIT_NAME",
        label: "Unit Name",
      },
      {
        id: 6,
        field: "SERIAL_NUM",
        label: "Serial Number",
      },
      {
        id: 4,
        label: "Actions",
        field: "SYS_ACTIONS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="Payment Info">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handleMoreInfo(data);
                  }}
                >
                  <i className="fa fa-info" />
                </button>
              </div>
              {sysJwt.isAuthorized(["CB3A"]) && (
                <div className="inner" title="Delete">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  handleDelete(row) {
    confirmAlert({
      title: "Delete Voucher",
      message: "Are you sure to delete this voucher?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            post(
              "/api/data/DeleteElectricityVoucher",
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "Cancel",
          onClick: () => null,
        },
      ],
    });
  }

  handleMoreInfo(row) {
    post(
      "/api/data/GetBillPaymentInfo",
      {
        paymentId: row.PAYMENT_ID,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        const jsonData = {
          "Payment ID": row.PAYMENT_ID,
          "Pay Amount": apiResponse.data.respData.payment.PAY_AMOUNT,
          "Pay Date": apiResponse.data.respData.payment.PAY_DATE,
          "Pay Method": apiResponse.data.respData.payment.PAY_METHOD,
          "Order ID": apiResponse.data.respData.payment.ORDER_ID,
          GET_STATUS_RESP:
            apiResponse.data.respData.order !== null
              ? apiResponse.data.respData.order.GET_STATUS_RESP
              : "",
        };

        confirmAlert({
          title: row.ID,
          childrenElement: () => (
            <InfoPopUp
              jsonData={jsonData}
              titlesColumnSize={4}
              valuesColumnSize={8}
              popUpWidth="1000px"
            />
          ),
          buttons: [
            {
              label: "Close",
              onClick: () => {
                null;
              },
            },
          ],
        });
      }
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    post(
      this.state.dataUrl,
      {
        typeId: this.state.queryString.typeId,
        unitId: this.state.queryString.unitId,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      console.log(apiResponse.data.respData.data);
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            <Button
              label="Back"
              icon=""
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                window.location.replace("/dashboard/vouchersstock");
              }}
            />
          </div>
        </div>
        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 25, 30]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default TypeVocuhers;
