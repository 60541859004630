/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import { unitInstallmentPrint } from "../../../utils/printFroms";

class Installments extends Component {
  state = {
    data: {
      unitName: "",
      categoryId: "",
      subcategoryId: "",
      blockName: "",
      floorNum: "",
      ownerId: "",
    },
    Username: "",
    allCategory: [],
    allSubcategory: [],
    allOwner: [],

    contentHeader: "Installments",
    addButtonLabel: "اضافة وحدة سكنية",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetInstallments",
    singleItemUrl: "/api/data/GetUnit",
    addUrl: "/api/data/AddUnit",
    editUrl: "/api/data/EditUnit",
    deleteUrl: "/api/data/DeleteUnit",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "اضافة وحدة سكنية",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل وحدة سكنية",
    modalSubmitButtonLabelEdit: "Edit",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "Unit Name",
        field: "UNIT_NAME",
      },
      {
        id: 154,
        label: "Owner Name",
        field: "OWNER_NAME",
      },
      {
        id: 13,
        label: "Block",
        field: "BLOCK_NAME",
      },
      {
        id: 2,
        label: "Price",
        field: "PRICE",
      },
      {
        id: 3,
        label: "Remaining Amount",
        field: "REMAINING_AMOUNT",
      },
      {
        id: 4,
        label: "Actions",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="Show Installments Recoreds">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() =>
                    window.location.replace(
                      "/dashboard/unitinstallments?unitId=" +
                        data.ID +
                        "&unitName=" +
                        data.UNIT_NAME +
                        "&ownerName=" +
                        data.OWNER_NAME
                    )
                  }
                >
                  <i className="fa fa-bars" />
                </button>
              </div>
              <div className="inner" title="Print Intallment">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handlePrint(data);
                  }}
                >
                  <i className="fa fa-print" />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
  };

  handlePrint(row) {
    post(
      "/api/data/GetUnitInstallments",
      { unitId: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      unitInstallmentPrint(
        apiResponse.data.respData.data,
        row,
        this.state.Username
      );
    });
  }

  componentDidMount() {
    this.getData();
    this.setState({
      Username: sysJwt.getUsername(),
    });
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["id"] = this.state.editEntityId;
    }
    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
      }
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
        </div>
        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 25, 30]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default Installments;
