/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import queryString from "query-string";
import { installmentPrint } from "../../../utils/printFroms";

class UnitInstallments extends Component {
  state = {
    data: {
      numberPayments: "",
      paymentAmount: "",
      periodOfPayment: "",
      startDate: "",
      amount: "",
      dueDate: "",
      payDate: "",
    },
    Username: "",
    queryString: queryString.parse(window.location.search),

    contentHeader:
      "Installment Records - " +
      queryString.parse(window.location.search).unitName,
    addButtonLabel: "Create Installment Schedule",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetUnitInstallments",
    singleItemUrl: "/api/data/GetUnitInstallment",
    addUrl: "/api/data/CreateUnitInstallments",
    editUrl: "/api/data/EditInstallment",
    deleteUrl: "/api/data/DeleteInstallment",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "Add Installment",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "Edit Installment",
    modalSubmitButtonLabelEdit: "Edit",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",
    inModalCreateMode: false,
    inModalPayMode: false,

    editEntityId: 0,
    tableData: [],
    installmentId: "",

    tableColumns: [
      {
        id: 1,
        field: "ID",
        label: "ID",
        width: "75px",
      },
      {
        id: 2,
        field: "AMOUNT",
        label: "Payment Amount",
      },
      {
        id: 3,
        field: "DUE_DATE",
        label: "Due Date",
      },
      {
        id: 4,
        field: "IS_PAID",
        label: "Paid",
        cellRenderer: ({ data }) => {
          return data.IS_PAID === "Y" ? "Yes" : "No";
        },
      },
      {
        id: 5,
        field: "PAY_DATE",
        label: "Pay Date",
      },
      {
        id: 6,
        label: "Insert Date",
        field: "INSERT_DATE",
      },
      {
        id: 7,
        label: "Actions",
        field: "SYS_ACTIONS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CB31"]) && (
                <div className="inner" title="Pay">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() =>
                      this.setState({
                        inModalPayMode: true,
                        installmentId: data.ID,
                      })
                    }
                  >
                    <i className="fa fa-money" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB2F"]) && (
                <div className="inner" title="Edit">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB30"]) && (
                <div className="inner" title="Delete">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
              <div className="inner" title="Print">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handlePrint(data);
                  }}
                >
                  <i className="fa fa-print" />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
  };

  handlePrint(row) {
    installmentPrint(
      row,
      this.state.queryString.unitName,
      this.state.queryString.ownerName,
      this.state.Username
    );
  }

  componentDidMount() {
    this.getData();
    this.setState({
      Username: sysJwt.getUsername(),
    });
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  closeModalCreate() {
    this.setState({
      inModalCreateMode: false,
    });
    this.getData();
  }

  closeModalPay() {
    this.setState({
      inModalPayMode: false,
    });
    this.getData();
  }

  getData() {
    post(
      this.state.dataUrl,
      { unitId: this.state.queryString.unitId },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["dueDate"] = apiResponse.data.respData.data.DUE_DATE;
      data["amount"] = apiResponse.data.respData.data.AMOUNT;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data.ID,
      });
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "Delete Installment Record",
      message: "Are you sure to delete this installment record?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "Cancel",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmitCreate() {
    post(
      this.state.addUrl,
      {
        unitId: this.state.queryString.unitId,
        numberPayments: this.state.data.numberPayments,
        paymentAmount: this.state.data.paymentAmount,
        periodOfPayment: this.state.data.periodOfPayment,
        startDate: this.state.data.startDate,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModalCreate();
      }
    });
  }

  doSubmitPay() {
    post(
      "/api/data/PayInstallment",
      {
        id: this.state.installmentId,
        payDate: this.state.data.payDate,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModalPay();
      }
    });
  }

  doSubmit() {
    if (this.state.inEditMode) {
      post(
        this.state.editUrl,
        {
          id: this.state.editEntityId,
          amount: this.state.data.amount,
          dueDate: this.state.data.dueDate,
        },
        this.props.updateIsLoading
      ).then((apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModal();
        }
      });
    } else {
      post(this.state.addUrl, null, this.props.updateIsLoading).then(
        (apiResponse) => {
          if (apiResponse.data.respCode === 0) {
            this.props.updateIsLoading(false);
          }
        }
      );
    }
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB2E"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inModalCreateMode: true });
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "amount",
                label: "Payment Amount",
                disabled: false,
              },
              {
                type: "datetime",
                id: "dueDate",
                label: "Due Date",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <Dialog
          visible={this.state.inModalCreateMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModalCreate()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">Create Installment Schedule</h5>}
          footer={
            <div>
              <Button
                label="Create Schedule"
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmitCreate()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModalCreate()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "numberPayments",
                label: "Number Of Payments",
                disabled: false,
              },
              {
                type: "text",
                id: "paymentAmount",
                label: "Single payment amount",
                disabled: false,
              },
              {
                type: "text",
                id: "periodOfPayment",
                label: "Duration between payments (in months)",
                disabled: false,
              },
              {
                type: "datetime",
                id: "startDate",
                label: "Starte Scheduling Date",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <Dialog
          visible={this.state.inModalPayMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModalPay()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">Pay Installment</h5>}
          footer={
            <div>
              <Button
                label="Pay"
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmitPay()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModalPay()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "datetime",
                id: "payDate",
                label: "Pay Date",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 25, 30]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default UnitInstallments;
