/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import InfoPopUp from "../../commonComponents/infoPopUp";
import { getStockColor } from "../../../utils/sysHelpers";

class VoucherStock extends Component {
  state = {
    data: {
      typeId: "",
      unitId: "",
      serialNumList: [],
    },

    serial: "",
    contentHeader: "Electricity Vouchers Stock",
    addButtonLabel: "Add Electricity Voucher",
    addButtonIcon: "fa fa-plus",
    stockAlert: [],
    allType: [],
    allUnits: [],

    dataUrl: "/api/data/GetVoucherStock",
    addUrl: "/api/data/AddElectricityVoucher",
    editUrl: "/api/data/EditCategory",
    deleteUrl: "/api/data/DeleteCategory",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "Add Electricity Voucher",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "Edit Electricity Voucher",
    modalSubmitButtonLabelEdit: "Edit",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "Type ID",
        field: "TYPE_ID",
      },
      {
        id: 2,
        label: "Voucher Type",
        field: "VOUCHER_TYPE_EN",
      },
      {
        id: 3,
        label: "Unit Name",
        field: "UNIT_NAME",
      },
      {
        id: 4,
        label: "Price",
        field: "PRICE",
      },
      {
        id: 5,
        label: "Remaining Quantity",
        field: "REMAIN_QTY",
        cellRenderer: ({ data }) => {
          return (
            <span
              style={{
                color: "white",
                backgroundColor: getStockColor(
                  data.REMAIN_QTY,
                  this.state.stockAlert
                ),
                "border-radius": "3px",
                width: "60px",
              }}
            >
              <center>
                <b>&nbsp;{data.REMAIN_QTY}&nbsp;</b>
              </center>
            </span>
          );
        },
      },
      {
        id: 6,
        label: "Actions",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="Show Type Vouchers">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() =>
                    window.location.replace(
                      "/dashboard/typevouchers?typeId=" +
                        data.TYPE_ID +
                        "&typeName=" +
                        data.VOUCHER_TYPE_EN +
                        "&unitId=" +
                        data.UNIT_ID +
                        "&unitName=" +
                        data.UNIT_NAME
                    )
                  }
                >
                  <i className="fa fa-plug" />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
    this.getAllTypes();
    this.getAllUnits();
  }

  getAllTypes() {
    post(
      "/api/data/GetAllElectricityVoucherTypes",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allType: apiResponse.data.respData.data,
      });
    });
  }

  getAllUnits() {
    post("/api/data/GetAllUnits", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          allUnits: apiResponse.data.respData.data,
        });
      }
    );
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
      allClaims: [],
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ stockAlert: apiResponse.data.respData.stockAlert });
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["nameEn"] = apiResponse.data.respData.data[0].NAME_EN;
      data["nameAr"] = apiResponse.data.respData.data[0].NAME_AR;
      data["nameKu"] = apiResponse.data.respData.data[0].NAME_KU;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "Delete Category",
      message: "Are you sure to delete this category?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "Cancel",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["id"] = this.state.editEntityId;
    }
    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      console.log(apiResponse);
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModal();
      }
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB3A"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "dropdown",
                id: "typeId",
                label: "Voucher Type",
                disabled: false,
                options: this.state.allType,
              },
              {
                type: "dropdown",
                id: "unitId",
                label: "Unit Name",
                disabled: false,
                options: this.state.allUnits,
              },
            ]}
          />
          <span>Serial Number</span>
          <div className="row">
            <input
              className="form-control form-control-sm col"
              onChange={(e) => this.setState({ serial: e.target.value })}
              value={this.state.serial}
            ></input>{" "}
            <button
              className="col-4"
              onClick={() => {
                if (this.state.serial) {
                  var data = this.state.data;
                  data["serialNumList"].push(this.state.serial);
                  this.setState({
                    data: data,
                    serial: "",
                  });
                }
              }}
            >
              Add to List
            </button>
          </div>
          <span>List of serials to be added</span>
          <table style={{ border: "1px solid black", width: "100%" }}>
            {this.state.data.serialNumList.map((item) => {
              <tr style={{ border: "1px solid black" }}>
                <td style={{ border: "1px solid black" }}>Serial Numbers</td>
                <td style={{ border: "1px solid black" }}>&nbsp;</td>
              </tr>;
              return (
                <tr style={{ border: "1px solid black" }}>
                  <td style={{ border: "1px solid black" }}>{item}</td>
                  <td style={{ border: "1px solid black" }}>
                    <a
                      onClick={() => {
                        var data = this.state.data;
                        data["serialNumList"] = data["serialNumList"].filter(
                          (e) => e !== item
                        );
                        this.setState({
                          data: data,
                          serial: "",
                        });
                      }}
                    >
                      <b>
                        <i className="fa fa-trash" />
                      </b>
                    </a>
                  </td>
                </tr>
              );
            })}
          </table>
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 25, 30]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default VoucherStock;
