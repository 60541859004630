import React, { Component } from "react";
import homelogo from "../../images/homeimg.jpg";
import { post } from "../../utils/sysAxios";
import "../../styles/charts.css";
import { getRandomColor } from "../../utils/sysHelpers";
import { Chart } from "primereact/chart";

class Home extends Component {
  state = {
    paymentsPerMethod: [],
    last15DayPayments: [],
    billStatus: [],
    usersStatus: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    post("/api/data/GetHomeCharts", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        console.log(apiResponse.data.respData);
        this.setState({
          paymentsPerMethod: apiResponse.data.respData.paymentsPerMethod,
          last15DayPayments: apiResponse.data.respData.last15DayPayments,
          billStatus: apiResponse.data.respData.billStatus,
          usersStatus: apiResponse.data.respData.usersStatus,
        });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.usersStatus ? (
          <div className="charts-container fade-in">
            <div className="home-charts-row">
              <div className="card" style={{ backgroundColor: "#E9E9E9" }}>
                <Chart
                  type="doughnut"
                  height="320px"
                  data={{
                    labels: this.state.usersStatus.map((e) => e.KEY),
                    datasets: [
                      {
                        data: this.state.usersStatus.map((e) => e.VALUE),
                        backgroundColor: this.state.usersStatus.map(
                          (e) => e.COLOR
                        ),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        text: "Users Status",
                      },
                    },
                  }}
                />
              </div>
              <div className="card" style={{ backgroundColor: "#E9E9E9" }}>
                <Chart
                  type="bar"
                  height="320px"
                  data={{
                    labels: this.state.billStatus.map((e) => e.KEY),
                    datasets: [
                      {
                        label: "Bills",
                        data: this.state.billStatus.map((e) => e.VALUE),
                        backgroundColor: this.state.billStatus.map(
                          (e) => e.COLOR
                        ),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        text: "Current Month Bills Status",
                      },
                    },
                  }}
                />
              </div>
              <div className="card" style={{ backgroundColor: "#E9E9E9" }}>
                <Chart
                  type="pie"
                  height="320px"
                  data={{
                    labels: this.state.paymentsPerMethod.map((e) => e.KEY),
                    datasets: [
                      {
                        data: this.state.paymentsPerMethod.map((e) => e.VALUE),
                        backgroundColor: this.state.paymentsPerMethod.map(() =>
                          getRandomColor()
                        ),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        text: "Last 30 Days Payments Per Method",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <br />
            <div className="home-charts-row">
              <div
                className="card card--bar"
                style={{ backgroundColor: "#E9E9E9" }}
              >
                <Chart
                  type="line"
                  height="320px"
                  data={{
                    labels: this.state.last15DayPayments.map((e) => e.KEY),
                    datasets: [
                      {
                        label: "Number of Payments",
                        data: this.state.last15DayPayments.map((e) => e.VALUE),
                        backgroundColor: "#0066cc",
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      y: {
                        position: "left",
                      },
                    },
                    plugins: {
                      title: {
                        display: true,
                        text: "Last 15 Days Payments",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="home-logo text-center">
            <img src={homelogo} width={1000} height={500} alt="HOME" />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Home;
