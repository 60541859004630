/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import queryString from "query-string";
import InfoPopUp from "../../commonComponents/infoPopUp";
import { payBillPrint } from "../../../utils/printFroms";

class ServicBills extends Component {
  state = {
    data: {
      notes: "",
      file: "",
    },

    queryString: queryString.parse(window.location.search),

    contentHeader:
      "Unit Contracts - " + queryString.parse(window.location.search).unitName,
    addButtonLabel: "Add Contract",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetUnitContacts",
    addUrl: "/api/data/CreateUnitInstallments",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "Add Installment",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "Edit Installment",
    modalSubmitButtonLabelEdit: "Edit",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",
    inModalCreateMode: false,
    inModalPayMode: false,
    inModalAddMode: false,

    editEntityId: 0,
    tableData: [],

    tableColumns: [
      {
        id: 1,
        field: "ID",
        label: "ID",
        width: "75px",
      },
      {
        id: 2,
        field: "USERNAME",
        label: "Username",
      },
      {
        id: 3,
        field: "NOTES",
        label: "Notes",
      },
      {
        id: 4,
        field: "ATTACHMENT",
        label: "Attachment",
        cellRenderer: ({ data }) => {
          return (
            <a href={data.ATTACHMENT} target="blank" style={{ color: "black" }}>
              View File
            </a>
          );
        },
      },
    ],
  };
  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModalPay() {
    this.setState({
      inModalPayMode: false,
    });
    this.getData();
  }

  getData() {
    post(
      this.state.dataUrl,
      { unitId: this.state.queryString.unitId },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  doSubmitAdd() {
    const data = new FormData();
    if (this.state.data.file !== null) {
      data.append("File", this.state.data.file, this.state.data.file.name);
    }

    data.append(
      "requestData",
      JSON.stringify({
        notes: this.state.data.notes,
        unitId: this.state.queryString.unitId,
      })
    );

    post("/api/data/AddUnitContract", data, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModalPay();
        }
      }
    );
  }

  closeModalAdd() {
    this.setState({
      inModalAddMode: false,
    });
    this.getData();
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            <Button
              label="Back"
              icon=""
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                window.location.replace("/dashboard/units");
              }}
            />
            &nbsp;
            {sysJwt.isAuthorized(["CB46"]) && (
              <Button
                label="Add Contract"
                icon="fa fa-plus"
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inModalAddMode: true });
                }}
              />
            )}
            &nbsp;
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalAddMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModalAdd()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">Add Contract</h5>}
          footer={
            <div>
              <Button
                label="Add"
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmitAdd()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModalAdd()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "notes",
                label: "Notes",
                disabled: false,
              },
              {
                type: "fileupload",
                id: "file",
                label: "Attachment",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 25, 30]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default ServicBills;
